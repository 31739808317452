<template>
  <div
    v-editable="blok"
    class="EditorialProductBlock"
    :class="desktopWidth('full')"
  >
    <div
      v-if="!uiStore.isMobile"
      class="grid grid-cols-12 gap-12 w-full"
    >
      <div
        class="grid"
        :class="{
          'col-span-3': products.length === 1,
          'col-span-6 grid-cols-2 gap-12': products.length === 2,
          'col-span-7 grid-cols-3 gap-12': products.length === 3,
        }"
      >
        <div
          v-for="(product) in products"
          :key="product.partNo"
        >
          <ProductCard
            :product="product"
            :enable-quick-buy="true"
            design="standard"
            :placement="100"
          />
        </div>
      </div>
      <div
        class="pl-20 pb-112 flex items-center"
        :class="{
          'pl-[52px]': products.length === 1,
          'col-span-6': products.length <= 2,
          'col-span-5': products.length === 3,
        }"
      >
        <div class="wysiwyg">
          <ExtendedWysiwyg :content="blok.content" />
        </div>
      </div>
    </div>
    <client-only v-else-if="loaded">
      <Swiper
        class="w-full"
        :space-between="4"
        :modules="[SwiperPagination]"
        :pagination="{
          dynamicBullets: true,
          clickable: false,
        }"
        :slides-per-view="products.length > 1 ? 2.1 : 2"
        :slides-offset-after="products.length > 1 ? 12 : 0"
      >
        <SwiperSlide class="wysiwyg smallMobileHeadlines p-12">
          <ExtendedWysiwyg :content="blok.content" />
        </SwiperSlide>
        <SwiperSlide
          v-for="(product, slideIndex) in products"
          :key="product.partNo"
        >
          <ProductCard
            :key="product.partNo + slideIndex"
            :product="product"
            :enable-quick-buy="true"
            design="standard"
            :placement="100"
          />
        </SwiperSlide>
      </Swiper>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import {
  ProductSelector,
  StoryBlokPropBase
} from '~/constants/types/storyblok';
import useDesktopWidth from '~/composeables/useDesktopWidth';
import { watch } from 'vue';
import { ISbRichtext } from 'storyblok-js-client';
import useStoryblokAdmin from '~/composeables/useStoryblokAdmin';
import useAlgoliaFetch from '~/composeables/useAlgoliaFetch';
import { useUiStore } from '~/store/ui';
import ProductCard from '~/components/product/ProductCard.vue';
import { useGlobalContentStore } from '~/store/globalContent';
import Product, { ProductModel } from '~/models/product';

const { isInStoryblokAdmin } = useStoryblokAdmin();
const { getProductsByUrl, convertRawToProducts } = useAlgoliaFetch();
const globalContent = useGlobalContentStore();

const uiStore = useUiStore();

type Props = StoryBlokPropBase & {
  products: ProductSelector[];
  content: ISbRichtext;
}
const props = defineProps<{
  blok: Props
}>();

const { desktopWidth } = useDesktopWidth(props);
const loaded = ref(false);

const productsPartNo = computed(()=> {
  return props.blok.products.filter((f) => f.product !== '').map((m) => m.product?.product?.url).filter((f) => f !== '');
});

const products = ref<ProductModel[]>([]);
const getProducts = async() => {
  if (productsPartNo.value.length) {
    const res = await getProductsByUrl(productsPartNo.value);
    if (res) {
      const splitProducts = convertRawToProducts(res.hits);
      products.value = splitProducts.map((m) => m.product);
      loaded.value = true;
    } else {
      console.log('@todo handle error');
    }
  } else {
    products.value = [];
  }
};
await getProducts();

if (isInStoryblokAdmin()) {
  watch(() => props.blok.products, async() => {
    await getProducts();
  });
}

const jsonLd = {
  '@context': 'https://schema.org/',
  '@type': 'ItemList',
  numberOfItems: products.value.length,
  itemListElement: products.value.map(productData => {
    const productItem = Product.create(productData);
    const activeVariant = productItem?.variants[0];
    return {
      '@type': 'Product',
      url: productItem.url,
      name: productItem.name,
      image: productItem.imageList,
      description: productItem.metaDescription,
      sku: productItem.partNo,
      mpn: productItem.partNo,
      brand: {
        '@type': 'Brand',
        name: productItem.brand.name,
      },
      offers: {
        '@type': 'Offer',
        url: productItem.url,
        priceCurrency: globalContent.getCurrency,
        itemCondition: 'https://schema.org/NewCondition',
        availability: activeVariant?.canAddToCart ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
      },
    };
  }),
} as any;

useJsonld(jsonLd);
</script>

<style lang="postcss">
</style>
